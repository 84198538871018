/* eslint-disable max-lines */
"use client";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButtonComponent from "../../atoms/iconButton";
import CountryListDropDown from "../countryListDropDown";
import { desktopIndiaConfig } from "../../../constants/mockData/mockData";
import Drawer from "../drawer";
import { useSelector } from "react-redux";
import useAnalytics from "../../../analytics/useAnalytics";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../analytics/clevertapConstants";
import isEmpty from "lodash.isempty";
import { COUNTRIES, COUNTRIES_URL, DELIVER_IN_60_COUNTRIES, ENGATI_ENDPOINT } from "../../../constants/common/common";
import Loader from "../../atoms/loader";
import { fetchSameDayCatalog } from "../../../utils/common";
import CookieUtil from "../../../utils/storage/cookieUtil";
import {
  INTERNATIONAL_PF_COOKIE,
  LAST_VISITED_INTERNATIONAL_CITY,
  LAST_VISITED_INTERNATIONAL_LOCATION,
} from "../../../constants/storage";
import { useABTestingView } from "../../../customHooks/clevertap/useABTestingView";
import CustomImage from "../../atoms/customImage";

/**
 * StickyBottomMenu component for displaying a fixed bottom menu with icons and text.
 * @component
 * @param {string} param.catalogId - The catalog identifier (e.g., "india").
 * @returns {JSX.Element} JSX representation of the component.
 */
const StickyBottomMenu = ({ catalogId = "", parentClass = "", isAllGiftActive = false }) => {
  const { trackEvent } = useAnalytics();
  const [selectedCountry, setSelectedCountry] = useState(desktopIndiaConfig?.defaultCountry);
  const [showCountryList, setShowCountryList] = useState(false);
  const [showGiftAssistantPopup, setShowGiftAssistantPopup] = useState(false);
  const [sameDay, setSameDay] = useState([]);
  const [activeItem, setActiveItem] = useState({
    home: !isAllGiftActive,
    abroad: false,
    allGifts: isAllGiftActive,
  });
  const [loading, setLoading] = useState(false);

  const checkLoginStatus = useSelector((state) => state?.fus?.loginDetail?.loggedin);
  const countryListData = useSelector((state) => state?.locationLock?.countryListData);
  const isGiftAssistant = useABTestingView("giftAssistant");

  const options = { maxAge: 60 * 60 * 24 * 7, path: "/", encode: decodeURIComponent };
  const pageStickyMenuData = {
    page_name: cleverTapCommonWords.HOME,
    page_type: cleverTapCommonWords.HOME,
    icon_name: null,
    icon_position: null,
  };

  /** */
  const fetchSameDayData = async () => {
    const res = await fetchSameDayCatalog();
    setSameDay(res);
  };

  useEffect(() => {
    if (catalogId !== COUNTRIES.INDIA) {
      fetchSameDayData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const country = countryListData?.filter((item) => catalogId === item?.catelogID)?.[0];

    if (!isEmpty(country)) setSelectedCountry(country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryListData]);

  useEffect(() => {
    /**
     * It will triggered when browser back button clicked.
     * @param {*} event
     */
    const handlePopState = (event) => {
      event.preventDefault();
      setShowGiftAssistantPopup(false);
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  /**
   * Toggles the visibility of the country list.
   * Sets the state to show or hide the country list.
   * @function onToggleCountryList
   */
  const onToggleCountryList = () => {
    setShowCountryList(!showCountryList);
    setActiveItem({
      home: !isAllGiftActive,
      abroad: false,
      allGifts: isAllGiftActive,
    });
  };

  /**
   * Toggle Gift Assistant
   */
  const onToggleGiftAssistant = () => {
    if (!showGiftAssistantPopup) {
      window.history.pushState(null, null, "#giftAssistant");
    } else {
      window.history.replaceState(null, null, " ");
    }
    setShowGiftAssistantPopup(!showGiftAssistantPopup);
  };

  /**
   * Handles click event for the Home menu item.
   * Toggles the active state of Home.
   * @function
   */
  const homeOnClick = () => {
    setActiveItem({
      home: true,
      abroad: false,
      allGifts: false,
    });
  };

  /**
   * Handles click event for the Abroad menu item.
   * Toggles the active state of Abroad.
   * @function
   */
  const abroadOnClick = () => {
    setActiveItem({
      home: false,
      abroad: true,
      allGifts: false,
    });
    setShowCountryList(true);
    trackEvent(
      cleverTapCommonEvents.bottomNavigationNavIconClicked,
      {
        ...pageStickyMenuData,
        icon_name: "Abroad",
        icon_position: 3,
      },
      ["clevertap"],
    );
  };

  /**
   * Handles the selection of a country.
   * Sets the selected country, hides the country list, and dispatches actions to update the state.
   * @function onCountryClickHandler
   * @param {Object} item - The selected country object.
   */
  const onCountryClickHandler = (item) => {
    setSelectedCountry(item);
    setLoading(true);
    if (item.countryCategoryID === "gifts") {
      window.location.href = "/";
    } else if (item?.catelogID === COUNTRIES.SINGAPORE) {
      window.location.href = COUNTRIES_URL.SINGAPORE.url;
    } else {
      window.location.href = `/${item.countryCategoryID}-lp`;
    }
    if (catalogId !== item.catelogID) {
      CookieUtil.deleteCookie(INTERNATIONAL_PF_COOKIE, options);
      CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_LOCATION, options);
      CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_CITY, options);
    }
    setActiveItem({
      home: !isAllGiftActive,
      abroad: false,
      allGifts: isAllGiftActive,
    });
  };

  const menuItems = [
    {
      id: "home",
      iconSrc: "/icons/fnp-gift-grey.svg",
      activeIconSrc: "/icons/fnp-gift.svg",
      text: "Home",
      url: "/",
      onClick: homeOnClick,
    },
    {
      id: "sameDay",
      iconSrc: "/icons/same-day.svg",
      text: "Same Day",
      url: "/same-day-delivery-gifts-lp",
    },
    {
      id: "allGifts",
      iconSrc: "/icons/gift-box.svg",
      activeIconSrc: "/icons/gift-box-active.svg",
      text: "All Gifts",
      url: `/allgifts?catalogId=${catalogId}`,
    },
    {
      id: "abroad",
      iconSrc: "/icons/global.svg",
      activeIconSrc: "/icons/global-active.svg",
      text: "Abroad",
      onClick: abroadOnClick,
    },
    {
      id: "giftAssistant",
      iconSrc: "/icons/giftAssistant.svg",
      activeIconSrc: "/icons/giftAssistant.svg",
      text: "AI Gifting",
      onClick: onToggleGiftAssistant,
    },
    {
      id: "myAccount",
      iconSrc: "/icons/user-square.svg",
      activeIconSrc: "/icons/user-square-active.svg",
      text: "Account",
      url: checkLoginStatus ? "/account/my-account" : "/login",
    },
  ];

  /**
   * Handles the click event for "same-day" items based on specific conditions.
   * Otherwise, it returns the item's landing URL as is.
   */
  const sameDayClickHandler = (item) => {
    if (item?.id === "sameDay" && !isEmpty(catalogId) && catalogId !== COUNTRIES.INDIA) {
      return `/${catalogId}${item?.url}`;
    } else if (item?.id === "home" && !isEmpty(catalogId) && catalogId !== COUNTRIES.INDIA) {
      return `/${catalogId}/gifts-lp`;
    }
    return item?.url;
  };

  /** */
  const renderIcons = (item, index) => {
    return (
      <li
        key={item.id}
        className={`flex flex-1 flex-col items-center justify-center ${activeItem[item.id] ? "before:absolute before:top-0 before:h-4 before:w-40 before:rounded-full before:bg-fnp-600" : ""}`}
      >
        <a
          className="w-full py-8"
          href={sameDayClickHandler(item)}
          onClick={() => {
            trackEvent(
              cleverTapCommonEvents.bottomNavigationNavIconClicked,
              {
                ...pageStickyMenuData,
                icon_name: item.text,
                icon_position: index,
              },
              ["clevertap"],
            );
          }}
        >
          <IconButtonComponent
            iconSrc={activeItem[item.id] ? item.activeIconSrc || item.iconSrc : item.iconSrc}
            imgHeight={35}
            imgWidth={42}
            onClick={item.onClick}
            label={item.text}
            labelColor={activeItem[item.id] ? "w-full text-fnp-300 font-normal" : "w-full text-grey-400 font-normal"}
            labelTextSize="text-10"
            classes="flex flex-col items-center justify-center gap-2 w-full"
          />
        </a>
      </li>
    );
  };

  return (
    <>
      <Drawer
        position="bottom"
        isOpen={showCountryList}
        onDrawerClose={onToggleCountryList}
        styles={`px-16 max-h-[80vh] ${showCountryList ? "pb-[40px]" : "pb-0"}`}
      >
        {loading && <Loader />}
        <CountryListDropDown
          onCountryClick={onCountryClickHandler}
          selectedCountry={selectedCountry}
          isShowCountryCode={false}
          isSearchEnable={true}
          title={DELIVER_IN_60_COUNTRIES}
        />
      </Drawer>
      <Drawer
        position="bottom"
        isOpen={showGiftAssistantPopup}
        onDrawerClose={onToggleGiftAssistant}
        styles={`px-16 h-full pb-[40px]`}
        isHideCloseBtn={true}
        isDrawerCustomStyles={false}
      >
        <div
          className="-me-16 -ms-16 flex gap-12 border-b-1 border-lightGrey bg-white-900 px-16 py-12"
          onClick={onToggleGiftAssistant}
        >
          <CustomImage
            src="/icons/arrow-left-all-gifts.svg"
            alt="chat back"
            width={24}
            height={24}
            sizes="10vw"
            isPriority={false}
          />
          <span className="text-16 font-600 capitalize leading-6 text-fnp-500">Chat</span>
        </div>
        <iframe
          border="0"
          src={ENGATI_ENDPOINT}
          target="_parent"
          height="100%"
          name="Gift Assitant"
          width="100%"
          title="Gift Assistant"
          className="h-full w-full"
        />
      </Drawer>
      <div
        className={`fixed bottom-0 left-0 z-40 w-full justify-center bg-white-900 shadow-top md:hidden ${parentClass}`}
        id="sticky_bottom_menu"
      >
        <ul className="flex w-full justify-between gap-8">
          {menuItems.map((item, index) => {
            if (
              item.id === "giftAssistant" &&
              (isGiftAssistant !== "true" || (isGiftAssistant === "true" && catalogId !== COUNTRIES.INDIA))
            ) {
              return;
            }
            return item?.url ? (
              item.id !== "sameDay" ? (
                renderIcons(item, index)
              ) : catalogId === COUNTRIES.INDIA || sameDay.includes(catalogId) || !sameDay.length ? (
                renderIcons(item, index)
              ) : null
            ) : (
              <li key={index} className="flex flex-1 flex-col items-center justify-center">
                <IconButtonComponent
                  iconSrc={activeItem[item.id] ? item.activeIconSrc || item.iconSrc : item.iconSrc}
                  imgHeight={35}
                  imgWidth={42}
                  onClick={item.onClick}
                  label={item.text}
                  labelColor={
                    activeItem[item.id] ? "w-full text-fnp-300 font-normal" : "w-full text-grey-400 font-normal"
                  }
                  labelTextSize="text-10"
                  classes="py-8 flex flex-col items-center justify-center gap-2 w-full"
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

StickyBottomMenu.propTypes = {
  catalogId: PropTypes.string,
  parentClass: PropTypes.string,
  isAllGiftActive: PropTypes.bool,
};

export default StickyBottomMenu;
