/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/storage/localStorageUtil";
import isEmpty from "lodash.isempty";
import { isJsonString } from "../../utils/common";

/**
 * Custom hook to handle A/B Testing View
 * @param {*} windowKey
 * @returns
 */
export const useABTestingView = (windowKey) => {
  const [abTestingView, setAbTestingView] = useState(null);
  const [parseData, setParseData] = useState(null);
  let fetchVariablesPromise = null;

  /**
   * Fetch CleverTap variables and process local storage data
   * @returns
   */
  const fetchVariablesHandler = async () => {
    if (!window?.clevertap) return;

    const localStorageKey = "WZRK_PE";
    const cachedData = getLocalStorageItem(localStorageKey);
    const parsedData = cachedData && isJsonString(cachedData) ? JSON.parse(cachedData) : {};
    const expiryTime = parsedData?.expiry;

    // If cached data is valid, resolve immediately
    if (!isEmpty(parsedData?.value) && expiryTime > Date.now()) {
      return Promise.resolve(parsedData.value);
    }

    // If a fetch is already in progress, return the shared promise
    if (fetchVariablesPromise) {
      return fetchVariablesPromise;
    }

    // Fetch variables from CleverTap if cache is invalid or expired
    fetchVariablesPromise = new Promise((resolve, reject) => {
      window.clevertap.fetchVariables((variables, error) => {
        if (error) {
          fetchVariablesPromise = null;
          reject(error);
        } else {
          const updatedData = {
            value: getLocalStorageItem(localStorageKey),
            expiry: Date.now() + 5 * 60 * 1000, // 5 minutes expiry
          };
          setLocalStorageItem(localStorageKey, JSON.stringify(updatedData));
          fetchVariablesPromise = null;
          resolve(variables);
        }
      });
    });
    return fetchVariablesPromise;
  };

  /**
   * Handles fetching CleverTap variables and localStorakge data
   */
  const ABTestingViewHandler = async () => {
    try {
      // Wait for CleverTap variables to be fetched
      await fetchVariablesHandler();

      const cachedData = getLocalStorageItem("WZRK_PE");
      if (!cachedData) return;

      const parsedData = JSON.parse(cachedData);
      if (!parsedData?.value) return;

      const decodedData = decodeURIComponent(parsedData.value);
      const ABObject = JSON.parse(decodedData);
      if (ABObject[windowKey] !== abTestingView) {
        setParseData(ABObject);
      }
    } catch (error) {
      console.error("Error fetching variables or processing data:", error);
    }
  };

  /**
   * Event listener to trigger ABTestingViewHandler on load
   */
  useEffect(() => {
    if (typeof window !== "undefined" && window?.clevertap) {
      ABTestingViewHandler();
    }
  }, [typeof window !== "undefined" && window?.clevertap]);

  /**
   * Update abTestingView whenever parseData changes
   */
  useEffect(() => {
    if (parseData && parseData[windowKey] !== abTestingView) {
      setAbTestingView(parseData[windowKey]);
    }
  }, [parseData, windowKey]);

  return abTestingView;
};
